@import 'src/styles/main';

$header-height: 94px;

.root {
  display: flex;
  height: 100vh;
  flex-direction: column;

  .header {
    display: flex;
    width: 100%;
    height: $header-height;
    flex-grow: 1;
    justify-content: space-between;
  }

  .breadcrumb {
    margin: 24px 0 0 20px;
  }

  .closeButtonWrapper {
    margin: 20px 20px 0 0;
  }

  .closeButton {
    width: 20px;
    height: 20px;
  }

  .contentWrapper {
    display: flex;
    height: calc(100% - $header-height);
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    width: 80%;
    min-width: 1084px;
    height: 100%;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }

  .title {
    margin-bottom: 32px;
    line-height: 36px;
    white-space: pre-wrap;

    @media (width <= $breakpoint-windows) {
      margin-bottom: 20px;
      font-size: 24px;
    }
  }

  .table {
    width: 100%;
    margin-bottom: 32px;

    @media (width <= $breakpoint-windows) {
      margin-bottom: 20px;
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0 72px;
    text-align: center;

    @media (width <= $breakpoint-windows) {
      margin: auto 0 20px;
    }
  }

  .previousTypography {
    height: 28px;
    margin-top: 24px;
    cursor: pointer;

    @media (width <= $breakpoint-windows) {
      margin-top: 12px;
    }
  }
}
