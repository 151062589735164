@import './src/styles/main';

.container {
  background-color: $white;
  text-align: right;
}

.checkText {
  padding: 4px 16px 4px 4px;
  border-radius: 12px;
}

.errorMessage {
  display: inline-block;
  margin-bottom: 4px;
  color: $red;
}

.errorWrapper {
  display: inline-block;
  margin-right: 32px;
}

.editButton {
  width: 180px;
}
