@import './src/styles/main';
@import '../../variables';

$each-category-padding-height: 10px;
$title-height: 20px;
$font-size-s: 10px;
$font-size-m: 12px;
$font-size-l: 14px;

.root {
  width: 100%;
  margin-top: 12px;
}

.eachCategory {
  height: calc(
    (100vh - $header-height - $footer-height - $info-height) / 3
  ); // ページの高さからヘッダー、フッター、情報エリアの高さを引いて3分割する。

  box-sizing: border-box;
  padding: $each-category-padding-height 0px;
  margin: 0px 20px;
}

.messageBody {
  margin-bottom: 8px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  white-space: pre-wrap;
}

.title {
  display: flex;
  height: $title-height;
  align-items: center;
  margin-bottom: 12px;

  .icon {
    width: 20px;
    height: $title-height;
    &.colorBlack {
      path {
        fill: $black;
      }
    }

    @media (width <= $breakpoint-windows) {
      width: 18px;
    }
  }
  .text {
    margin-left: 4px;
    font-size: $font-size-l;
    font-weight: 700;
    line-height: $title-height;

    @media (width <= $breakpoint-windows) {
      font-size: $font-size-m;
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin: 40px 0;

  .notificationButton {
    width: 88%;
    border-radius: 12px;

    @media (width <= $breakpoint-windows) {
      font-size: $font-size-l;
    }
  }
}

.notificationCount {
  margin-left: 4px;
  font-size: $font-size-l;
  font-weight: 500;

  @media (width <= $breakpoint-windows) {
    font-size: $font-size-m;
  }
}

.addNotificationButton {
  border: none;
  margin-left: auto;
  background-color: transparent;
  color: $blue;
  cursor: pointer;
  font-size: $font-size-m;
  font-weight: 700;

  @media (width <= $breakpoint-windows) {
    font-size: $font-size-s;
  }
}

.date {
  margin-left: 8px;
}

.fontInfo {
  font-size: $font-size-l;
  font-weight: 400;

  @media (width <= $breakpoint-windows) {
    font-size: $font-size-m;
  }
}

.sentDate {
  display: flex;
  align-self: center;
  color: $gray03;

  .status {
    margin-right: 8px;
  }
}

.unread {
  color: $red;
  font-weight: 700;
}
