@import './src/styles/main';

.root {
  padding-bottom: 4px;
}

.row {
  height: 52px;
  cursor: pointer;
  &:hover {
    background-color: $white-gray;
  }
}

.statusInactive {
  color: $gray03;
}

.statusActive {
  color: $green;
}

.status {
  padding-left: 4px;
}

.statusWord {
  padding-left: 8px;
}
