@import './src/styles/main';

.button {
  width: 24px;
  height: 24px;
  padding: 0;
  border: 1px solid $light-gray;
  border-radius: 50%;
  background: $white;
  cursor: pointer;
  outline: none;

  &:active {
    @include _hoverColor($white);
  }

  .iconWrapper {
    display: flex;
    justify-content: center;
  }
}
