@import './src/styles/main';
@import '../../variables';

.code {
  color: $gray03;
  font-size: 20px;
  font-weight: 700;
}

.title {
  margin-bottom: 1rem;
  margin-left: 16px;
  color: $black1000;
  font-size: 24px;
  font-weight: 700;
}

.statusContainer {
  margin-top: 12px;
}

.status {
  margin-left: 6px;
  font-size: 12px;
}

.label {
  margin-left: 20px;
  font-size: 0.8rem;
  font-weight: 700;
}

.updateAt {
  margin-left: 20px;
  font-size: 0.8rem;
}
