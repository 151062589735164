@import './src/styles/main';

.root {
  .listItem {
    width: 100%;
    border-radius: 8px;
  }

  .listItem:hover {
    background-color: $black50;
  }

  .title {
    font-size: 16px;
    font-weight: 400;

    @media (width <= $breakpoint-windows) {
      font-size: 14px;
    }
  }

  .svgIcon {
    width: 24px;
    fill: black;

    @media (width <= $breakpoint-windows) {
      width: 18px;
    }
  }

  .listItem:hover .svgIcon {
    fill: $black;
  }

  .selectedItem {
    background-color: $black50;
    color: $primary;
  }

  .listItemText {
    display: flex;
    height: 28px;
    align-items: center;
    margin-left: 8px;
  }

  .selectedTitle {
    font-weight: 700;
  }

  .badge {
    margin-left: 8px;
  }
}
