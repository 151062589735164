@use 'sass:map';

// color
$primary: #121f98;
$primary-dark: #000850;
$alert: #fff3f0;
$red: #ff0000;
$red2: #f44336;
$blue: #121f98;
$light-blue: #f5f6ff;
$vermilion: #e3594a;
$green: #4ae3ac;
$green02: #16ce8c;
$gray01: #f8f5f1;
$gray02: #eeeae5;
$gray03: #b7b7b7;
$gray04: #d6d3d0;
$gray05: #edebe8;
$gray-hover: #cccccc;
$black: #000000;
$black100: rgba(0, 0, 0, 0.1);
$white: #ffffff;
$pink01: #ffdddd;
$pink02: #fff1f1;
$white-gray: #f5f5f5;
$light-gray: #e1e1e1;
$light-gray00: #f8f9fc;
$light-gray02: #f1f3f4;
$light-gray03: #f9f9f9;
$light-gray04: #d3d3d3;
$error: #ce0000;
$black50: rgba(0, 0, 0, 0.05);
$black100: rgba(0, 0, 0, 0.1);
$black200: rgba(0, 0, 0, 0.2);
$black300: rgba(0, 0, 0, 0.3);
$black430: rgba(0, 0, 0, 0.43);
$black400: rgba(0, 0, 0, 0.4);
$black500: rgba(0, 0, 0, 0.5);
$black1000: rgba(0, 0, 0, 1);
$unavailable: #969696;
