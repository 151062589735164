@import './src/styles/main';
@import '../../variables';

.row {
  cursor: pointer;
}

.disabled {
  background-color: white !important;
  span {
    color: $gray03 !important;
  }
  svg {
    opacity: 0.4;
  }
}

.associated {
  background-color: $light-blue;
}

.notAssociated {
  background-color: $white;
}

.iconCell {
  background-color: $white;
}

.status {
  padding-left: 4px;
}

.iconNotAssociated {
  button {
    width: 24px;
    height: 24px;
    border: 1px solid $gray03;
    background-color: $white;
  }
  svg {
    path {
      stroke: $black;
    }
  }
}

.iconAssociated {
  button {
    width: 24px;
    height: 24px;
    border: 1px solid $primary-dark;
    background-color: $primary;
    &:hover {
      background-color: $primary-dark;
    }
  }
  svg {
    width: 12px;
    height: 12px;
    margin-top: 2px;
    path {
      stroke: $white;
      stroke-width: 0.4;
    }
  }
}
