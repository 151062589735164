.header {
  display: flex;
  justify-content: space-between;
}

.filterWrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.filter {
  margin-right: 8px;

  &:last-child {
    margin-right: unset;
  }
}

.searchField {
  align-self: center;
}

.checkAria {
  margin-left: 20px;
}

.checkText {
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  user-select: none;
}
