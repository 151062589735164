@import './src/styles/main';

// NOTE: このcssはTenantCreateとTenantUpdateの両方で利用している

.root {
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow-y: scroll;
}

.header {
  display: flex;
  padding: 52px 60px 0;
  margin-bottom: 16px;
}

.title {
  display: flex;
  flex-direction: column;
  & > h1 {
    font-size: 24px;
  }
}

.description {
  padding: 20px 20px 20px 28px;
  border-radius: 15px;
  margin: auto;
  margin-left: auto;
  background-color: $light-gray;
}

.subTitle {
  display: flex;
  margin: 8px 0px;
  .item {
    font-size: 20px;
  }
  .item:first-child {
    margin-right: 20px;
  }
  .code {
    color: $gray03;
  }
}

.filterBar {
  @media (max-width: $breakpoint-windows) {
    min-width: 60vw;
  }
}

.main {
  width: 112vw;
  padding-left: 60px;
  margin-bottom: 100px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px 4px;
  border-top: 1px solid $light-gray;
  background-color: white;

  .footerErrorMessage {
    margin-right: 32px;
    color: $red;
    font-size: 16px;
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .errorMessage {
    margin-right: 40px;
    color: $red;
    font-size: 16px;
  }

  button {
    right: 12px;
    height: 40px;
    margin-right: 20px;
  }
}
