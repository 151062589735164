@import './src/styles/main';
@import '../../variables';

$each-category-padding-height: 10px;
$title-height: 20px;
$font-size-s: 10px;
$font-size-m: 12px;
$font-size-l: 14px;

.root {
  width: 100%;
}

.eachCategory {
  height: calc(
    (100vh - $header-height - $footer-height - $info-height) / 3
  ); // ページの高さからヘッダー、フッター、情報エリアの高さを引いて3分割する。

  box-sizing: border-box;
  padding: $each-category-padding-height 0px;
  margin: 0px 20px;
}

.reportMessageWrapper {
  padding: 10px 0px;
  margin: 0px 20px;
}

.messageBody {
  margin-bottom: 8px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  white-space: pre-wrap;
}

.textareaContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.textarea {
  width: 100%;
  height: calc(100% - $title-height);
  box-sizing: border-box;
  padding: 16px;
  border-color: $box-border-color;
  border-radius: 10px;
  font-size: $font-size-l;
  line-height: 16px;
  &::placeholder {
    color: $gray03;
  }

  @media (width <= $breakpoint-windows) {
    font-size: $font-size-m;
  }
}

.title {
  display: flex;
  height: $title-height;
  align-items: center;
  margin-bottom: 12px;

  .icon {
    width: 20px;
    height: $title-height;
    &.colorBlack {
      path {
        fill: $black;
      }
    }

    @media (width <= $breakpoint-windows) {
      width: 18px;
    }
  }
  .text {
    margin-left: 4px;
    font-size: $font-size-l;
    font-weight: 700;
    line-height: $title-height;

    @media (width <= $breakpoint-windows) {
      font-size: $font-size-m;
    }
  }
}

.fixedMemo {
  height: calc(100% - $title-height - $each-category-padding-height);
  min-height: 50px;
  box-sizing: border-box;
  padding: 8px 12px;
  border: solid 1px $box-border-color;
  border-radius: 10px;
  background-color: $report-message-color;
  overflow-y: scroll;
}

.pathToDetail {
  max-width: 50px;
  align-items: center;
  margin-left: auto;
  color: $blue;
  font-size: $font-size-m;
  font-weight: 700;
  line-height: 20px;
  text-align: right;

  @media (width <= $breakpoint-windows) {
    font-size: $font-size-s;
  }
}
